.SpreadsheetInterface {
  width: 100%;
  min-height: 300px;
  display: grid;
  gap: 50px;
  padding: 50px;
  grid-template-columns: 1fr 1fr;
  grid-auto-flow: row;
}
.SpreadsheetInterface button,
.SpreadsheetInterface input,
.SpreadsheetInterface label.upload-spreadsheet {
  min-height: 100px;
  min-width: 100px;
  border: 1px solid black;
  background-color: lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  font-weight: normal;
}
.SpreadsheetInterface button input[type=file],
.SpreadsheetInterface input input[type=file],
.SpreadsheetInterface label.upload-spreadsheet input[type=file] {
  display: none;
}
.SpreadsheetInterface button::placeholder,
.SpreadsheetInterface input::placeholder,
.SpreadsheetInterface label.upload-spreadsheet::placeholder {
  text-align: center;
}
.SpreadsheetInterface button:disabled,
.SpreadsheetInterface input:disabled,
.SpreadsheetInterface label.upload-spreadsheet:disabled {
  color: gray;
}
/*# sourceMappingURL=data:application/json;charset=utf-8;base64,eyJ2ZXJzaW9uIjozLCJzb3VyY2VSb290IjoiL3RtcC9idWlsZF9mMTcyMzNjNS9hcHAvamF2YXNjcmlwdC9jb21wb25lbnRzL3BsYW50aW5nL3Bsb3R0ZWQiLCJzb3VyY2VzIjpbIlNwcmVhZHNoZWV0SW50ZXJmYWNlLnNjc3MiXSwibmFtZXMiOltdLCJtYXBwaW5ncyI6IkFBQUE7RUFDRTtFQUNBO0VBQ0E7RUFHQTtFQUNBO0VBQ0E7RUFDQTs7QUFFQTtBQUFBO0FBQUE7RUFTRTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7RUFDQTtFQUNBO0VBQ0E7O0FBZEE7QUFBQTtBQUFBO0VBQ0U7O0FBRUY7QUFBQTtBQUFBO0VBQ0U7O0FBV0Y7QUFBQTtBQUFBO0VBQ0UiLCJzb3VyY2VzQ29udGVudCI6WyIuU3ByZWFkc2hlZXRJbnRlcmZhY2Uge1xuICB3aWR0aDogMTAwJTtcbiAgbWluLWhlaWdodDogMzAwcHg7XG4gIGRpc3BsYXk6IGdyaWQ7XG4gIC8vIGp1c3RpZnktY29udGVudDogc3BhY2UtZXZlbmx5O1xuICAvLyBhbGlnbi1pdGVtczogY2VudGVyO1xuICBnYXA6IDUwcHg7XG4gIHBhZGRpbmc6IDUwcHg7XG4gIGdyaWQtdGVtcGxhdGUtY29sdW1uczogMWZyIDFmcjtcbiAgZ3JpZC1hdXRvLWZsb3c6IHJvdztcblxuICBidXR0b24sXG4gIGlucHV0LFxuICBsYWJlbC51cGxvYWQtc3ByZWFkc2hlZXQge1xuICAgIGlucHV0W3R5cGU9XCJmaWxlXCJdIHtcbiAgICAgIGRpc3BsYXk6IG5vbmU7XG4gICAgfVxuICAgICY6OnBsYWNlaG9sZGVyIHtcbiAgICAgIHRleHQtYWxpZ246IGNlbnRlcjtcbiAgICB9XG4gICAgbWluLWhlaWdodDogMTAwcHg7XG4gICAgbWluLXdpZHRoOiAxMDBweDtcbiAgICBib3JkZXI6IDFweCBzb2xpZCBibGFjaztcbiAgICBiYWNrZ3JvdW5kLWNvbG9yOiBsaWdodGdyYXk7XG4gICAgZGlzcGxheTogZmxleDtcbiAgICBqdXN0aWZ5LWNvbnRlbnQ6IGNlbnRlcjtcbiAgICBhbGlnbi1pdGVtczogY2VudGVyO1xuICAgIGJvcmRlci1yYWRpdXM6IDEwcHg7XG4gICAgZm9udC13ZWlnaHQ6IG5vcm1hbDtcbiAgICAmOmRpc2FibGVkIHtcbiAgICAgIGNvbG9yOiBncmF5O1xuICAgIH1cbiAgfVxufVxuIl19 */